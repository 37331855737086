@import '../node_modules/govuk-frontend/dist/govuk/all.scss';
@import 'accessible-autocomplete';

html,
body {
    padding: 0;
    margin: 0;
    font-family:
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        Fira Sans,
        Droid Sans,
        Helvetica Neue,
        sans-serif;
}

* {
    box-sizing: border-box;
}

.empty-hint-section-break {
    margin-top: -5px;
}

/* TASK LIST */
.app-task-list {
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    min-width: 550px;
}

.app-task-list__section {
    display: table;
    font-size: 24px;
}

.app-task-list__section-number {
    width: 30px;
    padding-right: 0;
    display: inline-block;
    margin: 0;
}

.app-task-list__items {
    font-size: 19px;
    margin-bottom: 9px;
    list-style: none;
    padding-left: 30px;
    padding-bottom: 30px;
}

.app-task-list__item {
    border-bottom: 1px solid #b1b4b6;
    margin-bottom: 0 !important;
    padding-top: 10px;
    padding-bottom: 10px;
    clear: both;
    display: flex;
    justify-content: space-between;
}

.app-task-list__item:first-child {
    border-top: 1px solid #b1b4b6;
}

.app-task-list__task-name {
    display: inline-block;
    text-decoration: underline;
}

.govuk-date-input {
    font-size: 0;
}

.govuk-date-input:after {
    content: '';
    display: block;
    clear: both;
}

.govuk-date-input__item {
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 0;
}

.fieldset-container {
    margin-bottom: 50px;
}

.save-button {
    margin-right: 20px;
}

.delete-button {
    margin-right: 20px;
}

.body {
    min-height: 66vh;
    display: flex;
    flex-direction: column;
}

.footer {
    margin-top: auto;
}

.govuk-textarea {
    margin-bottom: 0px;
}

a[aria-disabled] {
    pointer-events: none;
    color: #666;
    text-decoration: none;
}

h1:focus-visible {
    outline: none;
}

ul li {
    padding-bottom: 10px;
}

.li-no-padding {
    padding-bottom: 0;
}

/* Animations */

@keyframes progressbar-bar1 {
    0% {
        left: -35%;
        right: 100%;
    }
    60% {
        left: 100%;
        right: -90%;
    }
    100% {
        left: 100%;
        right: -90%;
    }
}

@keyframes progressbar-bar2 {
    0% {
        left: -200%;
        right: 100%;
    }

    60% {
        left: 107%;
        right: -8%;
    }
    100% {
        left: 107%;
        right: -8%;
    }
}
